import * as tslib_1 from "tslib";
import { Input } from '@angular/core';
import { SettingsComponent } from './settings.component';
/**
 * @hidden
 */
var GaugeAreaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GaugeAreaComponent, _super);
    function GaugeAreaComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GaugeAreaComponent.propDecorators = {
        'background': [{ type: Input },],
        'border': [{ type: Input },],
        'height': [{ type: Input },],
        'margin': [{ type: Input },],
        'width': [{ type: Input },],
    };
    return GaugeAreaComponent;
}(SettingsComponent));
export { GaugeAreaComponent };
