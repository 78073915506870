import * as tslib_1 from "tslib";
import { Input, Component } from '@angular/core';
import { CollectionChangesService, ConfigurationService } from '../services';
import { CollectionItemComponent } from '../base-components';
/**
 * The configuration options for a pointer item of a LinearGauge.
 */
var LinearPointerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LinearPointerComponent, _super);
    function LinearPointerComponent(configurationService, collectionChangesService) {
        return _super.call(this, configurationService, collectionChangesService) || this;
    }
    LinearPointerComponent.decorators = [
        { type: Component, args: [{
                    providers: [ConfigurationService],
                    selector: 'kendo-lineargauge-pointer',
                    template: ''
                },] },
    ];
    /** @nocollapse */
    LinearPointerComponent.ctorParameters = function () { return [
        { type: ConfigurationService, },
        { type: CollectionChangesService, },
    ]; };
    LinearPointerComponent.propDecorators = {
        'border': [{ type: Input },],
        'color': [{ type: Input },],
        'margin': [{ type: Input },],
        'opacity': [{ type: Input },],
        'shape': [{ type: Input },],
        'size': [{ type: Input },],
        'value': [{ type: Input },],
    };
    return LinearPointerComponent;
}(CollectionItemComponent));
export { LinearPointerComponent };
