import * as tslib_1 from "tslib";
import { Input } from '@angular/core';
import { SettingsComponent } from './settings.component';
/**
 * @hidden
 */
var ScaleComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ScaleComponent, _super);
    function ScaleComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScaleComponent.propDecorators = {
        'labels': [{ type: Input },],
        'majorTicks': [{ type: Input },],
        'minorTicks': [{ type: Input },],
        'min': [{ type: Input },],
        'max': [{ type: Input },],
        'minorUnit': [{ type: Input },],
        'majorUnit': [{ type: Input },],
        'reverse': [{ type: Input },],
        'rangeSize': [{ type: Input },],
        'rangePlaceholderColor': [{ type: Input },],
    };
    return ScaleComponent;
}(SettingsComponent));
export { ScaleComponent };
