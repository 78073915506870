import { Injectable } from '@angular/core';
/**
 * @hidden
 */
var CollectionChangesService = /** @class */ (function () {
    function CollectionChangesService() {
    }
    CollectionChangesService.decorators = [
        { type: Injectable },
    ];
    /** @nocollapse */
    CollectionChangesService.ctorParameters = function () { return []; };
    return CollectionChangesService;
}());
export { CollectionChangesService };
