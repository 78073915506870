import * as tslib_1 from "tslib";
import { Input } from '@angular/core';
import { SettingsComponent } from './settings.component';
/**
 * @hidden
 */
var LabelsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LabelsComponent, _super);
    function LabelsComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LabelsComponent.propDecorators = {
        'background': [{ type: Input },],
        'border': [{ type: Input },],
        'color': [{ type: Input },],
        'font': [{ type: Input },],
        'format': [{ type: Input },],
        'margin': [{ type: Input },],
        'padding': [{ type: Input },],
        'content': [{ type: Input },],
        'visible': [{ type: Input },],
    };
    return LabelsComponent;
}(SettingsComponent));
export { LabelsComponent };
