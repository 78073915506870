import * as tslib_1 from "tslib";
import { Input } from '@angular/core';
import { CollectionItemComponent } from './collection-item.component';
/**
 * @hidden
 */
var RangeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RangeComponent, _super);
    function RangeComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RangeComponent.propDecorators = {
        'from': [{ type: Input },],
        'to': [{ type: Input },],
        'opacity': [{ type: Input },],
        'color': [{ type: Input },],
    };
    return RangeComponent;
}(CollectionItemComponent));
export { RangeComponent };
