import * as tslib_1 from "tslib";
import { Component, Input } from '@angular/core';
import { CollectionChangesService, ConfigurationService } from '../services';
import { CollectionItemComponent } from '../base-components';
/**
 * The configuration options for an ArcGauge color item.
 */
var ColorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ColorComponent, _super);
    function ColorComponent(configurationService, collectionChangesService) {
        return _super.call(this, configurationService, collectionChangesService) || this;
    }
    ColorComponent.decorators = [
        { type: Component, args: [{
                    providers: [ConfigurationService],
                    selector: 'kendo-arcgauge-color',
                    template: ''
                },] },
    ];
    /** @nocollapse */
    ColorComponent.ctorParameters = function () { return [
        { type: ConfigurationService, },
        { type: CollectionChangesService, },
    ]; };
    ColorComponent.propDecorators = {
        'color': [{ type: Input },],
        'opacity': [{ type: Input },],
        'from': [{ type: Input },],
        'to': [{ type: Input },],
    };
    return ColorComponent;
}(CollectionItemComponent));
export { ColorComponent };
